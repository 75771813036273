import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import LocalStorage from "../../utils/LocalStorgae";
/* eslint-disable */
import AuthorizedHeader from "../../components/layouts/AuthorizedHeader";
import GeneralPMSDashboard from "../../features/PMSDashboard/GlobalDashboard/GeneralPMSDashboard";
import FilterPill from "../../components/FilterPill";
import DateCalendar from "../../components/DateRange";
import {
  getComparePMSData,
  // getHeatMapData,
  getPMSBasedHeatmap,
  getPMSData
} from "../../store/apiSlice";
import {
  updateAdCampaignData,
  updateAdMediumData,
  updateAdPartnerData,
  updateAgeData,
  updateBookingGroup,
  updateChannelsData,
  updateCompareAdCampaignData,
  updateCompareAdMediumData,
  updateCompareAdPartnerData,
  updateCompareAgeData,
  updateCompareBookingGroup,
  updateCompareChannelsData,
  updateCompareCountriesData,
  updateCompareDaysOfStayData,
  updateCompareFilteredData,
  updateCompareGenderData,
  updateCompareGuestType,
  updateCompareInsights,
  updateCompareMonthlyBookingData,
  updateCompareMonthlyRequestData,
  updateCompareRoomsData,
  updateCompareSpendData,
  updateCompareTypeData,
  updateCompareVisitorsLineData,
  updateCountriesData,
  updateDaysOfStayData,
  updateFilteredData,
  updateGenderData,
  updateGuestType,
  updateInsights,
  updateMonthlyBookingData,
  updateMonthlyRequestData,
  updateRoomsData,
  updateSelectedHeatMap,
  updateSpendData,
  updateTypeData,
  updateVisitorsLineData
} from "../../store/slices/dataPMSSlice";
import {
  filterAccordingToSelected,
  getGenderDataSorted,
  getGraphDataSorted,
  GraphData,
  getGraphDataSortedOptimized,
  getInsights,
  getLineChartData,
  modifyData,
  compareAndMergeData,
  compareMergeReqBook
} from "../../utils/dataPMSFilter";
import {
  dataFilterFunction,
  getUniqueReservationCounts
} from "../../utils/dataFilter";
import storage from "../../utils/storage";
import Loading from "../../features/Placeholders/Loading";
import { updateSelectedPMSFilters } from "../../store/slices/generalSlice";
import ComparePMSDashboard from "../../features/PMSDashboard/GlobalDashboard/ComparePMSDashboard";
import {
  setCompareAdCampaignData,
  setCompareAdMediumData,
  setCompareAdPartnerData,
  setCompareAgeData,
  setCompareBookingGroup,
  setCompareChannelsData,
  setCompareCountriesData,
  setCompareDaysOfStayData,
  setCompareGenderData,
  setCompareGuestType,
  setCompareMonthlyBookingData,
  setCompareMonthlyRequestData,
  setCompareRoomsData,
  setCompareSpendData,
  setCompareTypeData,
  setCompareVisitorsLineData
} from "../../store/slices/comparePMSSlice";

export default function PmsDashboard() {
  const { t } = useTranslation();
  const language = LocalStorage.getItem("selectedLanguage");
  const dashboard = "pms";

  const dispatch = useDispatch();
  const dataValues = {
    reservation: "reservation_id",
    persona: "persona",
    date: "date",
    age: "age",
    days: "days",
    rooms: "roomType",
    revenue: "amount",
    booking: "booking_channel",
    gender: "gender",
    type: "type",
    arrival: "arrival",
    country: "country_code",
    spend: "spendRange",
    adPartner: "advertising_partner",
    adMedium: "advertising_medium",
    adCampaign: "advertising_campaign",
    guestType: "guest_type",
    bookingGroup: "booking_group"
  };
  const insightsTitle = {
    requests: t("PMSPage.requests"),
    bookings: t("PMSPage.bookings"),
    // roomReq: t("PMSPage.roomReq"),
    revenue: t("PMSPage.revenue"),
    conversionRate: t("PMSPage.convRate"),
    revenuePerBooking: t("PMSPage.revenuePerBooking")
  };

  const storedFilter = JSON.parse(storage.getItem("selectedPMS"));

  const {
    isLoading,
    isHeatmapLoading,
    selectedHeatMap,
    heatMapDateRange,
    heatmapData,
    allData: allData1,
    visitorsLineData: visitorsLineData1,
    roomsData: roomsData1,
    typeData: typeData1,
    channelsData: channelsData1,
    daysOfStayData: daysOfStayData1,
    guestType: guestType1,
    bookingGroup: bookingGroup1,
    adPartnerData: adPartnerData1,
    adMediumData: adMediumData1,
    adCampaignData: adCampaignData1,
    monthlyRequestData: monthlyRequestData1,
    monthlyBookingData: monthlyBookingData1,
    countriesData: countriesData1,
    ageData: ageData1,
    spendData: spendData1,
    genderData: genderData1,
    filteredData: filteredData1
  } = useSelector((state) => state.PMSData);

  const {
    allData: allData2,
    filteredData: filteredData2,
    visitorsLineData: visitorsLineData2,
    roomsData: roomsData2,
    typeData: typeData2,
    channelsData: channelsData2,
    daysOfStayData: daysOfStayData2,
    guestType: guestType2,
    bookingGroup: bookingGroup2,
    adPartnerData: adPartnerData2,
    adMediumData: adMediumData2,
    adCampaignData: adCampaignData2,
    monthlyRequestData: monthlyRequestData2,
    monthlyBookingData: monthlyBookingData2,
    countriesData: countriesData2,
    ageData: ageData2,
    spendData: spendData2,
    genderData: genderData2
  } = useSelector((state) => state.PMSdataToCompare);

  const {
    websiteID,
    dateRange,
    compareDateRange,
    isCompare,
    timeType,
    selectedPMSFilters,
    createdBy: customerId
  } = useSelector((state) => state.generalData);

  useEffect(() => {
    if (isCompare) {
      if (allData1.length > 0 || allData2.length > 0) {
        dispatch(updateSelectedPMSFilters(storedFilter ?? {}));
      }
    } else if (allData1.length > 0)
      dispatch(updateSelectedPMSFilters(storedFilter ?? {}));
  }, [allData1, allData2]);

  const separateData1 = (data) => {
    dispatch(updateInsights(getInsights(data)));

    const typeData = dataFilterFunction(data, dataValues.type);
    const guestTypeContent = dataFilterFunction(data, dataValues.guestType);
    const guestTypeData = getUniqueReservationCounts(guestTypeContent);
    const graphData = new GraphData();
    graphData.compute(data, dataValues);

    const roomGraphData = graphData.getGraphData(dataValues.rooms);

    const channelGraphData = graphData.getGraphData(dataValues.booking);
    const daysOfStayGraphData = graphData.getGraphData(dataValues.days);
    const bookingGroupData = graphData.getGraphData(dataValues.bookingGroup);
    const adPartnerData = graphData.getGraphData(dataValues.adPartner);
    const adMediumData = graphData.getGraphData(dataValues.adMedium);
    const adCampaignData = graphData.getGraphData(dataValues.adCampaign);
    const ageGraphData = graphData.getGraphData(dataValues.age);
    const monthlyRequestGraphData = graphData.getGraphData(dataValues.arrival);

    const monthlyBookingGraphData = getGraphDataSorted(
      dataFilterFunction(typeData.reservation, dataValues.arrival),
      false,
      true
    );

    const worldGraphData = graphData.getGraphData(dataValues.country);

    const genderGraphData = getGenderDataSorted(
      dataFilterFunction(data, dataValues.gender),
      t
    );

    const spendGraphData = getGraphDataSorted(
      dataFilterFunction(data, dataValues.spend),
      true
    );

    const visitorsLineGraphData = getLineChartData(
      dataFilterFunction(data, dataValues.date),
      isCompare
        ? {
            startDate: compareDateRange.startDate,
            endDate: compareDateRange.endDate
          }
        : dateRange,
      timeType,
      heatMapDateRange
    );

    const typeGraphData = getGraphDataSorted(typeData);

    if (selectedPMSFilters?.[t("PMSPage.roomsTitle")]) {
      dispatch(
        updateRoomsData({
          ...filterAccordingToSelected(
            roomsData1,
            selectedPMSFilters?.[t("PMSPage.roomsTitle")]
          )
        })
      );
    } else
      dispatch(
        updateRoomsData({
          labels: roomGraphData?.map((ele) => ele.label),
          roomReq: roomGraphData?.map((ele) => ele.roomRequests),
          requests: roomGraphData?.map((ele) => ele.requests),
          bookings: roomGraphData?.map((ele) => ele.bookings)
        })
      );

    if (selectedPMSFilters?.[t("PMSPage.channelTitle")]) {
      dispatch(
        updateChannelsData({
          ...filterAccordingToSelected(
            channelsData1,
            selectedPMSFilters?.[t("PMSPage.channelTitle")]
          )
        })
      );
    } else
      dispatch(
        updateChannelsData({
          labels: channelGraphData.map((ele) => ele.label),
          requests: channelGraphData.map((ele) => ele.requests),
          bookings: channelGraphData?.map((ele) => ele.bookings)
        })
      );

    if (selectedPMSFilters?.[t("PMSPage.daysOfStayTitle")]) {
      dispatch(
        updateDaysOfStayData({
          ...filterAccordingToSelected(
            daysOfStayData1,
            selectedPMSFilters?.[t("PMSPage.daysOfStayTitle")]
          )
        })
      );
    } else
      dispatch(
        updateDaysOfStayData({
          labels: daysOfStayGraphData.map((ele) => ele.label),
          roomReq: daysOfStayGraphData.map((ele) => ele.roomRequests),
          requests: daysOfStayGraphData.map((ele) => ele.requests),
          bookings: daysOfStayGraphData.map((ele) => ele.bookings)
        })
      );
    if (selectedPMSFilters?.[t("PMSPage.guestTypeTitle")]) {
      dispatch(
        updateGuestType({
          ...filterAccordingToSelected(
            guestType1,
            selectedPMSFilters?.[t("PMSPage.guestTypeTitle")]
          )
        })
      );
    } else
      dispatch(
        updateGuestType({
          labels: guestTypeData.map((ele) => ele.label),
          requests: guestTypeData.map((ele) => ele.requests),
          bookings: guestTypeData.map((ele) => ele.bookings)
        })
      );
    if (selectedPMSFilters?.[t("PMSPage.bookingGroupTitle")]) {
      dispatch(
        updateBookingGroup({
          ...filterAccordingToSelected(
            bookingGroup1,
            selectedPMSFilters?.[t("PMSPage.bookingGroupTitle")]
          )
        })
      );
    } else
      dispatch(
        updateBookingGroup({
          labels: bookingGroupData.map((ele) => ele.label),
          requests: bookingGroupData.map((ele) => ele.requests),
          bookings: bookingGroupData.map((ele) => ele.bookings)
        })
      );
    if (selectedPMSFilters?.[t("PMSPage.adCampaignTitle")]) {
      dispatch(
        updateAdCampaignData({
          ...filterAccordingToSelected(
            adCampaignData1,
            selectedPMSFilters?.[t("PMSPage.adCampaignTitle")]
          )
        })
      );
    } else
      dispatch(
        updateAdCampaignData({
          labels: adCampaignData.map((ele) => ele.label),
          requests: adCampaignData.map((ele) => ele.requests),
          bookings: adCampaignData.map((ele) => ele.bookings)
        })
      );
    if (selectedPMSFilters?.[t("PMSPage.adMediumTitle")]) {
      dispatch(
        updateAdMediumData({
          ...filterAccordingToSelected(
            adMediumData1,
            selectedPMSFilters?.[t("PMSPage.adMediumTitle")]
          )
        })
      );
    } else
      dispatch(
        updateAdMediumData({
          labels: adMediumData.map((ele) => ele.label),
          requests: adMediumData.map((ele) => ele.requests),
          bookings: adMediumData.map((ele) => ele.bookings)
        })
      );
    if (selectedPMSFilters?.[t("PMSPage.adPartnerTitle")]) {
      dispatch(
        updateAdPartnerData({
          ...filterAccordingToSelected(
            adPartnerData1,
            selectedPMSFilters?.[t("PMSPage.adPartnerTitle")]
          )
        })
      );
    } else
      dispatch(
        updateAdPartnerData({
          labels: adPartnerData.map((ele) => ele.label),
          requests: adPartnerData.map((ele) => ele.requests),
          bookings: adPartnerData.map((ele) => ele.bookings)
        })
      );

    if (selectedPMSFilters?.[t("PMSPage.ageTitle")]) {
      dispatch(
        updateAgeData({
          ...filterAccordingToSelected(
            ageData1,
            selectedPMSFilters?.[t("PMSPage.ageTitle")]
          )
        })
      );
    } else
      dispatch(
        updateAgeData({
          labels: ageGraphData.map((ele) => ele.label),
          requests: ageGraphData.map((ele) => ele.requests),
          bookings: ageGraphData.map((ele) => ele.bookings)
        })
      );

    if (selectedPMSFilters?.[t("PMSPage.monthBookingTitle")]) {
      dispatch(
        updateMonthlyBookingData({
          ...filterAccordingToSelected(
            monthlyBookingData1,
            selectedPMSFilters?.[t("PMSPage.monthBookingTitle")]
          )
        })
      );
    } else
      dispatch(
        updateMonthlyBookingData({
          labels: monthlyBookingGraphData.map((ele) => ele.label),
          roomBook: monthlyBookingGraphData.map((ele) => ele.roomRequests),
          bookings: monthlyBookingGraphData.map((ele) => ele.requests)
        })
      );

    if (selectedPMSFilters?.[t("PMSPage.monthlyReqTitle")]) {
      dispatch(
        updateMonthlyRequestData({
          ...filterAccordingToSelected(
            monthlyRequestData1,
            selectedPMSFilters?.[t("PMSPage.monthlyReqTitle")]
          )
        })
      );
    } else
      dispatch(
        updateMonthlyRequestData({
          labels: monthlyRequestGraphData.map((ele) => ele.label),
          roomReq: monthlyRequestGraphData.map((ele) => ele.roomRequests),
          requests: monthlyRequestGraphData.map((ele) => ele.requests)
        })
      );

    if (selectedPMSFilters?.[t("PMSPage.worldTitle")]) {
      dispatch(
        updateCountriesData({
          ...filterAccordingToSelected(
            countriesData1,
            selectedPMSFilters?.[t("PMSPage.worldTitle")]
          )
        })
      );
    } else
      dispatch(
        updateCountriesData({
          labels: worldGraphData.map((ele) => ele.label),
          requests: worldGraphData.map((ele) => ele.requests)
        })
      );
    if (selectedPMSFilters?.[t("PMSPage.spendTitle")]) {
      dispatch(
        updateSpendData({
          ...filterAccordingToSelected(
            spendData1,
            selectedPMSFilters?.[t("PMSPage.spendTitle")]
          )
        })
      );
    } else
      dispatch(
        updateSpendData({
          labels: spendGraphData.map((ele) => ele.label),
          requests: spendGraphData.map((ele) => ele.requests),
          bookings: spendGraphData.map((ele) => ele.bookings)
        })
      );
    if (!selectedPMSFilters?.[t("PMSPage.genderTitle")])
      dispatch(
        updateGenderData({
          labels: genderGraphData.map((ele) => ele.label),
          requests: genderGraphData.map((ele) => ele.requests)
        })
      );
    if (selectedPMSFilters?.[t("PMSPage.requestTypeTitle")]) {
      updateTypeData({
        ...filterAccordingToSelected(
          typeData1,
          selectedPMSFilters?.[t("PMSPage.requestTypeTitle")]
        )
      });
    } else
      dispatch(
        updateTypeData({
          labels: typeGraphData.map((ele) => ele.label),
          requests: typeGraphData.map((ele) => ele.requests)
        })
      );

    if (!selectedPMSFilters?.[t("common.date")])
      dispatch(
        updateVisitorsLineData({
          labels: visitorsLineGraphData.map((ele) => ele.label),
          revenue: visitorsLineGraphData.map((ele) => ele.revenue),
          bookings: visitorsLineGraphData.map((ele) => ele.bookings),
          requests: visitorsLineGraphData.map((ele) => ele.requests)
        })
      );
  };
  const separateData2 = (data) => {
    dispatch(updateCompareInsights(getInsights(data)));

    const typeData = dataFilterFunction(data, dataValues.type);
    const guestTypeContent = dataFilterFunction(data, dataValues.guestType);
    const guestTypeData = getUniqueReservationCounts(guestTypeContent);

    const graphData = new GraphData();
    graphData.compute(data, dataValues);

    const roomGraphData = graphData.getGraphData(dataValues.rooms);
    const channelGraphData = graphData.getGraphData(dataValues.booking);
    const daysOfStayGraphData = graphData.getGraphData(dataValues.days);
    const bookingGroupData = graphData.getGraphData(dataValues.bookingGroup);
    const adPartnerData = graphData.getGraphData(dataValues.adPartner);
    const adMediumData = graphData.getGraphData(dataValues.adMedium);
    const adCampaignData = graphData.getGraphData(dataValues.adCampaign);
    const ageGraphData = graphData.getGraphData(dataValues.age);
    const monthlyRequestGraphData = graphData.getGraphData(dataValues.arrival);

    const monthlyBookingGraphData = getGraphDataSorted(
      dataFilterFunction(typeData.reservation, dataValues.arrival),
      false,
      true
    );

    const worldGraphData = graphData.getGraphData(dataValues.country);

    const genderGraphData = getGenderDataSorted(
      dataFilterFunction(data, dataValues.gender),
      t
    );

    const spendGraphData = getGraphDataSorted(
      dataFilterFunction(data, dataValues.spend),
      true
    );

    const visitorsLineGraphData = getLineChartData(
      dataFilterFunction(data, dataValues.date),
      {
        startDate: compareDateRange.compareStartDate,
        endDate: compareDateRange.compareEndDate
      },
      timeType,
      heatMapDateRange
    );

    const typeGraphData = getGraphDataSorted(typeData);

    if (selectedPMSFilters?.[t("PMSPage.roomsTitle")]) {
      dispatch(
        updateCompareRoomsData({
          ...filterAccordingToSelected(
            roomsData2,
            selectedPMSFilters?.[t("PMSPage.roomsTitle")]
          )
        })
      );
    } else
      dispatch(
        updateCompareRoomsData({
          labels: roomGraphData?.map((ele) => ele.label),
          roomReq: roomGraphData?.map((ele) => ele.roomRequests),
          requests: roomGraphData?.map((ele) => ele.requests),
          bookings: roomGraphData?.map((ele) => ele.bookings)
        })
      );

    if (selectedPMSFilters?.[t("PMSPage.channelTitle")]) {
      dispatch(
        updateCompareChannelsData({
          ...filterAccordingToSelected(
            channelsData2,
            selectedPMSFilters?.[t("PMSPage.channelTitle")]
          )
        })
      );
    } else
      dispatch(
        updateCompareChannelsData({
          labels: channelGraphData?.map((ele) => ele.label),
          requests: channelGraphData?.map((ele) => ele.requests),
          bookings: channelGraphData?.map((ele) => ele.bookings)
        })
      );

    if (selectedPMSFilters?.[t("PMSPage.daysOfStayTitle")]) {
      dispatch(
        updateCompareDaysOfStayData({
          ...filterAccordingToSelected(
            daysOfStayData2,
            selectedPMSFilters?.[t("PMSPage.daysOfStayTitle")]
          )
        })
      );
    } else
      dispatch(
        updateCompareDaysOfStayData({
          labels: daysOfStayGraphData.map((ele) => ele.label),
          roomReq: daysOfStayGraphData.map((ele) => ele.roomRequests),
          requests: daysOfStayGraphData.map((ele) => ele.requests),
          bookings: daysOfStayGraphData.map((ele) => ele.bookings)
        })
      );
    if (selectedPMSFilters?.[t("PMSPage.guestTypeTitle")]) {
      dispatch(
        updateCompareGuestType({
          ...filterAccordingToSelected(
            guestType2,
            selectedPMSFilters?.[t("PMSPage.guestTypeTitle")]
          )
        })
      );
    } else
      dispatch(
        updateCompareGuestType({
          labels: guestTypeData.map((ele) => ele.label),
          requests: guestTypeData.map((ele) => ele.requests),
          bookings: guestTypeData.map((ele) => ele.bookings)
        })
      );
    if (selectedPMSFilters?.[t("PMSPage.bookingGroupTitle")]) {
      dispatch(
        updateCompareBookingGroup({
          ...filterAccordingToSelected(
            bookingGroup2,
            selectedPMSFilters?.[t("PMSPage.bookingGroupTitle")]
          )
        })
      );
    } else
      dispatch(
        updateCompareBookingGroup({
          labels: bookingGroupData.map((ele) => ele.label),
          requests: bookingGroupData.map((ele) => ele.requests),
          bookings: bookingGroupData.map((ele) => ele.bookings)
        })
      );
    if (selectedPMSFilters?.[t("PMSPage.adCampaignTitle")]) {
      dispatch(
        updateCompareAdCampaignData({
          ...filterAccordingToSelected(
            adCampaignData2,
            selectedPMSFilters?.[t("PMSPage.adCampaignTitle")]
          )
        })
      );
    } else
      dispatch(
        updateCompareAdCampaignData({
          labels: adCampaignData.map((ele) => ele.label),
          requests: adCampaignData.map((ele) => ele.requests),
          bookings: adCampaignData.map((ele) => ele.bookings)
        })
      );
    if (selectedPMSFilters?.[t("PMSPage.adMediumTitle")]) {
      dispatch(
        updateCompareAdMediumData({
          ...filterAccordingToSelected(
            adMediumData2,
            selectedPMSFilters?.[t("PMSPage.adMediumTitle")]
          )
        })
      );
    } else
      dispatch(
        updateCompareAdMediumData({
          labels: adMediumData.map((ele) => ele.label),
          requests: adMediumData.map((ele) => ele.requests),
          bookings: adMediumData.map((ele) => ele.bookings)
        })
      );
    if (selectedPMSFilters?.[t("PMSPage.adPartnerTitle")]) {
      dispatch(
        updateCompareAdPartnerData({
          ...filterAccordingToSelected(
            adPartnerData2,
            selectedPMSFilters?.[t("PMSPage.adPartnerTitle")]
          )
        })
      );
    } else
      dispatch(
        updateCompareAdPartnerData({
          labels: adPartnerData.map((ele) => ele.label),
          requests: adPartnerData.map((ele) => ele.requests),
          bookings: adPartnerData.map((ele) => ele.bookings)
        })
      );

    if (selectedPMSFilters?.[t("PMSPage.ageTitle")]) {
      dispatch(
        updateCompareAgeData({
          ...filterAccordingToSelected(
            ageData2,
            selectedPMSFilters?.[t("PMSPage.ageTitle")]
          )
        })
      );
    } else
      dispatch(
        updateCompareAgeData({
          labels: ageGraphData.map((ele) => ele.label),
          requests: ageGraphData.map((ele) => ele.requests),
          bookings: ageGraphData.map((ele) => ele.bookings)
        })
      );

    if (selectedPMSFilters?.[t("PMSPage.monthBookingTitle")]) {
      dispatch(
        updateCompareMonthlyBookingData({
          ...filterAccordingToSelected(
            monthlyBookingData2,
            selectedPMSFilters?.[t("PMSPage.monthBookingTitle")]
          )
        })
      );
    } else
      dispatch(
        updateCompareMonthlyBookingData({
          labels: monthlyBookingGraphData.map((ele) => ele.label),
          roomBook: monthlyBookingGraphData.map((ele) => ele.roomRequests),
          bookings: monthlyBookingGraphData.map((ele) => ele.requests)
        })
      );

    if (selectedPMSFilters?.[t("PMSPage.monthlyReqTitle")]) {
      dispatch(
        updateCompareMonthlyRequestData({
          ...filterAccordingToSelected(
            monthlyRequestData2,
            selectedPMSFilters?.[t("PMSPage.monthlyReqTitle")]
          )
        })
      );
    } else
      dispatch(
        updateCompareMonthlyRequestData({
          labels: monthlyRequestGraphData.map((ele) => ele.label),
          roomReq: monthlyRequestGraphData.map((ele) => ele.roomRequests),
          requests: monthlyRequestGraphData.map((ele) => ele.requests)
        })
      );

    if (selectedPMSFilters?.[t("PMSPage.worldTitle")]) {
      dispatch(
        updateCompareCountriesData({
          ...filterAccordingToSelected(
            countriesData2,
            selectedPMSFilters?.[t("PMSPage.worldTitle")]
          )
        })
      );
    } else
      dispatch(
        updateCompareCountriesData({
          labels: worldGraphData.map((ele) => ele.label),
          requests: worldGraphData.map((ele) => ele.requests)
        })
      );
    if (selectedPMSFilters?.[t("PMSPage.spendTitle")]) {
      dispatch(
        updateCompareSpendData({
          ...filterAccordingToSelected(
            spendData2,
            selectedPMSFilters?.[t("PMSPage.spendTitle")]
          )
        })
      );
    } else
      dispatch(
        updateCompareSpendData({
          labels: spendGraphData.map((ele) => ele.label),
          requests: spendGraphData.map((ele) => ele.requests),
          bookings: spendGraphData.map((ele) => ele.bookings)
        })
      );
    if (!selectedPMSFilters?.[t("PMSPage.genderTitle")])
      dispatch(
        updateCompareGenderData({
          labels: genderGraphData.map((ele) => ele.label),
          requests: genderGraphData.map((ele) => ele.requests)
        })
      );
    if (selectedPMSFilters?.[t("PMSPage.requestTypeTitle")]) {
      updateCompareTypeData({
        ...filterAccordingToSelected(
          typeData2,
          selectedPMSFilters?.[t("PMSPage.requestTypeTitle")]
        )
      });
    } else
      dispatch(
        updateCompareTypeData({
          labels: typeGraphData.map((ele) => ele.label),
          requests: typeGraphData.map((ele) => ele.requests)
        })
      );

    if (!selectedPMSFilters?.[t("common.date")])
      dispatch(
        updateCompareVisitorsLineData({
          labels: visitorsLineGraphData.map((ele) => ele.label),
          revenue: visitorsLineGraphData.map((ele) => ele.revenue),
          bookings: visitorsLineGraphData.map((ele) => ele.bookings),
          requests: visitorsLineGraphData.map((ele) => ele.requests)
        })
      );
  };

  // general function for filtering the data based on the common selected filters
  const filterData = (data) => {
    let tempData = data;

    if (selectedPMSFilters[t("common.date")]) {
      tempData = tempData.filter((ele) =>
        selectedPMSFilters[t("common.date")].includes(ele[dataValues.date])
      );
    }

    if (selectedPMSFilters[t("PMSPage.spendTitle")]) {
      tempData = tempData.filter((ele) =>
        selectedPMSFilters[t("PMSPage.spendTitle")].includes(
          ele[dataValues.spend]
        )
      );
    }

    if (selectedPMSFilters[t("PMSPage.roomsTitle")]) {
      tempData = tempData.filter((ele) =>
        selectedPMSFilters[t("PMSPage.roomsTitle")].includes(
          ele[dataValues.rooms]
        )
      );
    }
    if (selectedPMSFilters[t("PMSPage.channelTitle")]) {
      tempData = tempData.filter((ele) =>
        selectedPMSFilters[t("PMSPage.channelTitle")].includes(
          ele[dataValues.booking]
        )
      );
    }
    if (selectedPMSFilters[t("PMSPage.daysOfStayTitle")]) {
      tempData = tempData.filter((ele) =>
        selectedPMSFilters[t("PMSPage.daysOfStayTitle")].includes(
          ele[dataValues.days]
        )
      );
    }
    if (selectedPMSFilters[t("PMSPage.guestTypeTitle")]) {
      tempData = tempData.filter((ele) =>
        selectedPMSFilters[t("PMSPage.guestTypeTitle")].includes(
          ele[dataValues.guestType]
        )
      );
    }
    if (selectedPMSFilters[t("PMSPage.bookingGroupTitle")]) {
      tempData = tempData.filter((ele) =>
        selectedPMSFilters[t("PMSPage.bookingGroupTitle")].includes(
          ele[dataValues.bookingGroup]
        )
      );
    }
    if (selectedPMSFilters[t("PMSPage.adMediumTitle")]) {
      tempData = tempData.filter((ele) =>
        selectedPMSFilters[t("PMSPage.adMediumTitle")].includes(
          ele[dataValues.adMedium]
        )
      );
    }
    if (selectedPMSFilters[t("PMSPage.adCampaignTitle")]) {
      tempData = tempData.filter((ele) =>
        selectedPMSFilters[t("PMSPage.adCampaignTitle")].includes(
          ele[dataValues.adCampaign]
        )
      );
    }
    if (selectedPMSFilters[t("PMSPage.adPartnerTitle")]) {
      tempData = tempData.filter((ele) =>
        selectedPMSFilters[t("PMSPage.adPartnerTitle")].includes(
          ele[dataValues.adPartner]
        )
      );
    }
    if (selectedPMSFilters[t("PMSPage.monthlyReqTitle")]) {
      tempData = tempData.filter((ele) =>
        selectedPMSFilters[t("PMSPage.monthlyReqTitle")].includes(
          ele[dataValues.arrival]
        )
      );
    }
    if (selectedPMSFilters[t("PMSPage.monthBookingTitle")]) {
      tempData = tempData.filter((ele) =>
        selectedPMSFilters[t("PMSPage.monthBookingTitle")].includes(
          ele[dataValues.arrival]
        )
      );
    }
    if (selectedPMSFilters[t("PMSPage.worldTitle")]) {
      tempData = tempData.filter((ele) =>
        selectedPMSFilters[t("PMSPage.worldTitle")].includes(
          ele[dataValues.country]
        )
      );
    }
    if (selectedPMSFilters[t("PMSPage.ageTitle")]) {
      tempData = tempData.filter((ele) =>
        selectedPMSFilters[t("PMSPage.ageTitle")].includes(ele[dataValues.age])
      );
    }

    if (selectedPMSFilters[t("PMSPage.genderTitle")]) {
      tempData = tempData.filter((ele) =>
        selectedPMSFilters[t("PMSPage.genderTitle")].includes(
          ele[dataValues.gender]
        )
      );
    }
    if (selectedPMSFilters[t("PMSPage.requestTypeTitle")]) {
      tempData = tempData.filter((ele) =>
        selectedPMSFilters[t("PMSPage.requestTypeTitle")].includes(
          ele[dataValues.type]
        )
      );
    }

    return tempData;
  };

  useEffect(() => {
    if (!isCompare) {
      if (
        selectedHeatMap === null &&
        dateRange.startDate &&
        dateRange.endDate
      ) {
        dispatch(
          getPMSData(
            {
              website_id: websiteID,
              dateRange: [{ ...dateRange }],
              customer_id: customerId
            },
            t
          )
        ); // fetches allData1
      }
    } else {
      if (
        compareDateRange.startDate &&
        compareDateRange.endDate &&
        compareDateRange.compareStartDate &&
        compareDateRange.compareEndDate
      ) {
        // fetches allData2
        dispatch(
          getComparePMSData(
            {
              website_id: websiteID,
              dateRange: [
                {
                  startDate: compareDateRange.startDate,
                  endDate: compareDateRange.endDate
                },
                {
                  startDate: compareDateRange.compareStartDate,
                  endDate: compareDateRange.compareEndDate
                }
              ],
              customer_id: customerId
            },
            t
          ) // need to update this to dateRange
        );
      }
    }
  }, [dateRange, compareDateRange, isCompare, websiteID, selectedHeatMap, t]);

  useEffect(() => {
    if (Object.keys(selectedPMSFilters).length > 0) {
      separateData1(filteredData1);
      if (isCompare) separateData2(filteredData2);
    } else {
      separateData1(modifyData(allData1, t));
      if (isCompare) separateData2(modifyData(allData2, t));
    }
    storage.setItem("selectedPMS", JSON.stringify(selectedPMSFilters));
  }, [allData1, filteredData1, allData2, filteredData2, t, language]);

  // calls the filter function when the selected filters are changed
  useEffect(() => {
    const filteredValues = filterData(modifyData(allData1, t));
    dispatch(updateFilteredData(filteredValues));

    if (isCompare) {
      dispatch(updateCompareFilteredData(filterData(modifyData(allData2, t))));
    }
  }, [selectedPMSFilters, t, language]);

  useEffect(() => {
    if (selectedHeatMap) {
      dispatch(
        getPMSBasedHeatmap(
          {
            website_id: websiteID,
            date: selectedHeatMap
          },
          t
        )
      );
    }
  }, [selectedHeatMap, t]);

  useEffect(() => {
    return () => {
      dispatch(updateSelectedHeatMap(null));
      storage.setItem("heatmap", null);
    };
  }, []);

  const updateCompareData = () => {
    dispatch(
      setCompareVisitorsLineData(
        compareMergeReqBook(visitorsLineData1, visitorsLineData2)
      )
    );
    dispatch(setCompareTypeData(compareAndMergeData(typeData1, typeData2)));
    dispatch(setCompareRoomsData(compareAndMergeData(roomsData1, roomsData2)));
    dispatch(
      setCompareChannelsData(compareAndMergeData(channelsData1, channelsData2))
    );
    dispatch(
      setCompareDaysOfStayData(
        compareAndMergeData(daysOfStayData1, daysOfStayData2)
      )
    );
    dispatch(setCompareGuestType(compareAndMergeData(guestType1, guestType2)));
    dispatch(
      setCompareBookingGroup(compareAndMergeData(bookingGroup1, bookingGroup2))
    );
    dispatch(
      setCompareAdMediumData(compareAndMergeData(adMediumData1, adMediumData2))
    );
    dispatch(
      setCompareAdCampaignData(
        compareAndMergeData(adCampaignData1, adCampaignData2)
      )
    );
    dispatch(
      setCompareAdPartnerData(
        compareAndMergeData(adPartnerData1, adPartnerData2)
      )
    );
    dispatch(
      setCompareMonthlyRequestData(
        compareAndMergeData(monthlyRequestData1, monthlyRequestData2)
      )
    );
    dispatch(
      setCompareMonthlyBookingData(
        compareAndMergeData(monthlyBookingData1, monthlyBookingData2)
      )
    );
    dispatch(
      setCompareCountriesData(
        compareAndMergeData(countriesData1, countriesData2)
      )
    );
    dispatch(setCompareAgeData(compareAndMergeData(ageData1, ageData2)));
    dispatch(setCompareSpendData(compareAndMergeData(spendData1, spendData2)));
    dispatch(
      setCompareGenderData(compareAndMergeData(genderData1, genderData2))
    );
  };

  useEffect(() => {
    if (
      isCompare &&
      visitorsLineData2?.labels &&
      countriesData2?.labels &&
      typeData2?.labels &&
      roomsData2?.labels &&
      channelsData2?.labels &&
      daysOfStayData2?.labels &&
      guestType2?.labels &&
      bookingGroup2?.labels &&
      adCampaignData2?.labels &&
      adMediumData2?.labels &&
      adPartnerData2?.labels &&
      monthlyRequestData2?.labels &&
      monthlyBookingData2?.labels &&
      ageData2?.labels &&
      spendData2?.labels &&
      genderData2?.labels
    ) {
      updateCompareData();
    }
  }, [
    isCompare,
    visitorsLineData2,
    countriesData2,
    typeData2,
    roomsData2,
    channelsData2,
    daysOfStayData2,
    guestType2,
    bookingGroup2,
    adCampaignData2,
    adMediumData2,
    adPartnerData2,
    monthlyRequestData2,
    monthlyBookingData2,
    ageData2,
    spendData2,
    genderData2
  ]);

  return (
    <div>
      <AuthorizedHeader />
      <div className="dashboard-body">
        <div className="container">
          <div className="flex-between filter-header">
            <FilterPill
              dashboard={dashboard}
              filterValues={selectedPMSFilters}
            />
            <DateCalendar />
          </div>
          {(isLoading || isHeatmapLoading) && <Loading />}
          {!isLoading &&
            !isHeatmapLoading &&
            (isCompare ? (
              <ComparePMSDashboard
                insightsTitle={insightsTitle}
                isCompare={isCompare}
                selectedFilters={selectedPMSFilters}
                dashboard={dashboard}
                updateSelectedFilter={updateSelectedPMSFilters}
              />
            ) : (
              <GeneralPMSDashboard
                insightsTitle={insightsTitle}
                isCompare={isCompare}
                selectedFilters={selectedPMSFilters}
                dashboard={dashboard}
                isHeatmapLoading={isHeatmapLoading}
                heatmapData={heatmapData}
                selectedHeatMap={selectedHeatMap}
                updateSelectedFilter={updateSelectedPMSFilters}
              />
            ))}
        </div>
      </div>
    </div>
  );
}
