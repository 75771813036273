import axios from "../../../../api/axios";

export const addCustomTag = (payload) =>
  axios.post("tag/addCustomTag", payload);

export const deleteWebsiteEventTag = (payload) =>
  axios.delete("tag/deleteWebsiteEventTag", payload);

export const deleteCustomLabelTag = (payload) =>
  axios.post("tag/MultiDeleteCustomTag", payload);

export const deleteCustomTag = (tagId, webID) =>
  axios.delete(`tag/deleteCustomTag/${tagId}/${webID}`);

export const getCustomTagsAPI = (websiteId) =>
  axios.get(`tag/getCustomTag/${websiteId}`);

export const addWebsiteEventTag = (payload) =>
  axios.post("tag/addWebsiteEventTag", payload);

export const updateWebsiteEventTag = (payload) =>
  axios.post("tag/updateWebsiteEventTag", payload);

export const getTagMangagerToken = (payload) =>
  axios.post("trackToken/getTagManagerToken", payload);
